import * as React from "react";
import { useEffect, useState } from "react";
import Login from "../components/modules/Login";
import Campaigns from "../components/modules/campaigns";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";

export default function StudiesPage({location}) {
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const checkUserToken = () => {
		const accessToken = localStorage.getItem("access_token");
		if (!accessToken || accessToken === "undefined") {
			setIsLoggedIn(false);
		} else {
			setIsLoggedIn(true);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		checkUserToken();
	}, []);

	return (
		<React.Fragment>
			{isLoading && !isLoggedIn && (
				<div className='flex items-center justify-center h-screen'>
					<div className='w-32 h-32 border-t-2 border-b-2 border-[#3D2EFF] rounded-full animate-spin'></div>
				</div>
			)}
			{!isLoading && !isLoggedIn && <Login />}
			{!isLoading && isLoggedIn && (
				<>
					<Helmet>
						<title>Glassview Origin - Campaigns</title>
					</Helmet>
					<Layout>
						<Campaigns/>
					</Layout>
				</>
			)}
		</React.Fragment>
	);
}
